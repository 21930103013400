import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

import Grid from '@mui/material/Unstable_Grid2';
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import PageCards from '../components/ui/PageCards';
import TextEditor from '../components/textEditor/TextEditor';

function Admin_Edit_Pages(props) {
	const [pageInfo, setPageInfo] = useState();
	const [main, setMain] = useState();
	const [valid, setValid] = useState(false);
	const [alert, setAlert] = useState({ message: '', type: '', alert: false });
	const [facility, setFacility] = useState('');

	//set dropdown facility
	const handleFacilityChange = (event) => {
		setFacility(event.target.value);
	};

	//gets the page info
	useEffect(() => {
		const getFacilityPageInfo = async () => {
			setPageInfo(null);
			const docRef = doc(props.db, 'settings', facility);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				setPageInfo(docSnap.data().pageInfo);
			} else {
				setPageInfo('');
			}
		};

		if (facility !== '') getFacilityPageInfo();
	}, [facility]);

	//handles the text changing from the editor
	const mainChangeHandler = (editorObj) => {
		setMain(editorObj.text);
		setValid(editorObj.valid);
	};

	//handles submit button
	const handleSubmit = async () => {
		const docRef = doc(props.db, 'settings', facility);

		try {
			await updateDoc(docRef, { pageInfo: main });
			setFacility('');
			setPageInfo(null);
			setAlert({ message: 'The page content was updated successfully!', type: 'success', alert: true });
			setTimeout(() => {
				setAlert({ alert: false });
			}, 5000);
		} catch (error) {
			setFacility('');
			setPageInfo(null);
			setAlert({
				message:
					'There was an error.  Please refresh the page and try again.  If you experience this error again, notify IT.',
				type: 'error',
				alert: true,
			});
			setTimeout(() => {
				setAlert({ alert: false });
			}, 5000);
		}
	};

	return (
		<Grid spacing={1} m={2}>
			<Grid xs={12}>
				<PageCards>
					<h1>ADMIN</h1>
					<h3>Select a facility to edit the page content.</h3>
				</PageCards>
			</Grid>

			<Grid xs={12}>
				{!alert.alert && (
					<PageCards>
						<Box
							display='flex-start'
							alignItems='center'
							justifyContent='center'
							sx={{ maxWidth: 150, margin: '1rem auto' }}
						>
							<FormControl fullWidth>
								<InputLabel id='facility-label'>Facility</InputLabel>
								<Select
									labelId='facility-label'
									id='facility-select'
									value={facility}
									label='Facility'
									onChange={handleFacilityChange}
								>
									<MenuItem value={'SBLH'}>SBLH</MenuItem>
									<MenuItem value={'SI'}>SI</MenuItem>
									<MenuItem value={'SLH'}>SLH</MenuItem>
									<MenuItem value={'SNGH'}>SNGH</MenuItem>
									<MenuItem value={'SOH'}>SOH</MenuItem>
									<MenuItem value={'SPAH'}>SPAH</MenuItem>
									<MenuItem value={'SVBGH'}>SVBGH</MenuItem>
									<MenuItem value={'SSU_SOH'}>SSU SOH</MenuItem>
								</Select>
							</FormControl>
						</Box>

						<TextEditor main={pageInfo} mainChangeHandler={mainChangeHandler} valid={valid} includeImages={true} />

						<Stack direction='row' spacing={2} justifyContent='center' mb={2} mt={3}>
							<Button
								disabled={valid && facility !== '' ? false : true}
								variant='contained'
								startIcon={<SaveRoundedIcon />}
								color='saveButton'
								onClick={handleSubmit}
							>
								Save
							</Button>
						</Stack>
					</PageCards>
				)}
				{alert.alert && (
					<PageCards>
						<Alert severity={alert.type}>{alert.message}</Alert>
					</PageCards>
				)}
			</Grid>
		</Grid>
	);
}

export default Admin_Edit_Pages;
