import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { onSnapshot, doc } from 'firebase/firestore';

import { timeoutCollection } from 'time-events-manager';
import { getDatabase, ref, onValue } from 'firebase/database';

import { db } from '../../App';

const AuthContext = React.createContext({
	token: '',
	isLoggedIn: false,
	isAdmin: false,
	login: (token) => {},
	logout: () => {},
	photo: '',
	userName: '',
	email: '',
});

/**
 * calculates the remaining time before the token expires
 * @param {number} expirationTime
 * @returns the time remaining in milliseconds
 */
const calculateRemainingTime = (expirationTime) => {
	expirationTime = Number(expirationTime);

	const adjustedExpirationTime = new Date(expirationTime);
	const expirationDate = new Date(adjustedExpirationTime);

	const remainingDuration = expirationDate - new Date();
	return remainingDuration;
};

//retrieves the login data if stored locally
const RetrieveStoredToken = () => {
	const storedToken = localStorage.getItem('token');

	//do nothing if no storedToken
	if (storedToken !== null) {
		const storedExpirationDate = localStorage.getItem('logoutTime');
		const remainingDuration = calculateRemainingTime(storedExpirationDate);
		const storedPhoto = localStorage.getItem('photo');
		const storedUserName = localStorage.getItem('userName');
		const storedEmail = localStorage.getItem('email');
		let storedAdmin = localStorage.getItem('admin');
		if (storedAdmin === 'true') {
			storedAdmin = true;
		} else {
			storedAdmin = false;
		}

		//logout if storedExpirationDate has expired
		if (new Date(Number(storedExpirationDate)) < new Date()) {
			return {
				token: storedToken,
				remainingDuration: remainingDuration,
				photo: storedPhoto,
				userName: storedUserName,
				email: storedEmail,
				admin: storedAdmin,
				refresh: false,
				resetTimer: false,
				logout: true,
			};
		}

		//refresh if remainingDuration < 60 seconds
		if (remainingDuration < 60 * 1000) {
			// console.log('attempt refresh');
			return {
				token: storedToken,
				remainingDuration: remainingDuration,
				photo: storedPhoto,
				userName: storedUserName,
				email: storedEmail,
				admin: storedAdmin,
				refresh: true,
				resetTimer: false,
				logout: false,
			};
		}

		//else return data and resetTimer
		return {
			token: storedToken,
			remainingDuration: remainingDuration,
			photo: storedPhoto,
			userName: storedUserName,
			email: storedEmail,
			admin: storedAdmin,
			refresh: false,
			resetTimer: true,
			logout: false,
		};
	}
};

//authContext main function
export const AuthContextProvider = (props) => {
	const auth = getAuth();
	const database = getDatabase();

	//checks if data is stored locally first
	const tokenData = useRef(useMemo(() => RetrieveStoredToken(), []));
	const initialToken = tokenData?.current?.token || null;
	const initialPhoto = tokenData?.current?.photo || null;
	const initialUserName = tokenData?.current?.userName || null;
	const initialEmail = tokenData?.current?.email || null;
	const initialAdmin = tokenData?.current?.admin || null;
	const initialDataRetrieved = tokenData?.current?.dataRetrieved || null;

	let refreshTimer, refreshTimes;

	const [token, setToken] = useState(initialToken);
	const userIsLoggedIn = !!token;
	const [admin, setAdmin] = useState(initialAdmin);
	const [photo, setPhoto] = useState(initialPhoto);
	const [userName, setUserName] = useState(initialUserName);
	const [email, setEmail] = useState(initialEmail);
	const [dataRetrieved, setDataRetrieved] = useState(initialDataRetrieved);

	//logs user out and resets all localStorage and state
	const logoutHandler = useCallback(() => {
		// console.log('logged out');
		// console.log(new Date());
		setToken(null);
		setAdmin(false);
		setPhoto(null);
		setUserName(null);

		localStorage.clear();
		timeoutCollection.removeAll();

		signOut(auth);
	}, [auth]);

	//attemps to refresh the token
	const refreshTokenHandler = useCallback(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				// console.log(user);
				user
					.getIdToken(true)
					.then((result) => {
						// console.log(result);

						//only refresh up to 12 times
						refreshTimes = Number(localStorage.getItem('refreshTimes'));
						if (refreshTimes <= 12) {
							const expirationTime = user.stsTokenManager.expirationTime;
							const photo = user.photoURL;
							const userName = user.displayName;
							const email = user.email;
							const uid = user.uid;

							loginHandler(token, expirationTime, photo, userName, email, uid);

							refreshTimes += 1;
							localStorage.setItem('refreshTimes', String(refreshTimes));
							// console.log('refreshed user');
							// console.log(new Date());
						} else {
							//else logout
							logoutHandler();
						}
					})
					.catch((err) => console.log(err));
			} else {
				// User is signed out
				logoutHandler();
			}
		});
	}, [auth, logoutHandler, token]);

	//logs user in
	const loginHandler = (token, expirationTime, photo, userName, email, uid) => {
		// console.log(token);
		setToken(token);
		setPhoto(photo);
		setUserName(userName);
		setEmail(email);

		const remainingTime = calculateRemainingTime(expirationTime - 1000 * 30);
		refreshTimer = setTimeout(refreshTokenHandler, remainingTime);

		localStorage.setItem('token', token);
		localStorage.setItem('photo', photo);
		localStorage.setItem('userName', userName);
		localStorage.setItem('email', email);
		localStorage.setItem('logoutTime', new Date().getTime() + remainingTime);

		//if first login, then create refreshTimes
		if (!localStorage.getItem('refreshTimes')) {
			localStorage.setItem('refreshTimes', String(0));
		}
	};

	//resets refreshTimer
	if (tokenData && tokenData.resetTimer === true) {
		timeoutCollection.removeAll();
		refreshTimer = setTimeout(refreshTokenHandler, tokenData.remainingDuration);
		tokenData.resetTimer = false;
	}

	//attempts to refresh token
	if (tokenData && tokenData.refresh === true) {
		refreshTokenHandler();
		tokenData.refresh = false;
	}

	//sets custom claims
	let unsub = () => {};
	const [user, setUser] = useState(null);
	const previousToken = useRef(null);
	auth.onAuthStateChanged((user) => {
		setUser(user);
	});

	if (user && auth?.currentUser?.uid) {
		// console.log('dataRetrieved:', dataRetrieved);
		unsub = onSnapshot(doc(db, `Users`, auth.currentUser.uid), async (doc) => {
			// console.log('doc', doc.data());
			const newToken = await user.getIdTokenResult(true);

			if (newToken.token !== previousToken.current) {
				// console.log(newToken.claims);
				previousToken.current = newToken.token;
			}

			setDataRetrieved(true);
			localStorage.setItem('dataRetrieved', true);
		});
	}

	if (unsub && dataRetrieved) {
		// Only unsubscribe if data has been retrieved
		// console.log('Unsubscribing...');
		unsub();
	}

	//logouts user
	if (tokenData && tokenData.logout === true) {
		logoutHandler();
		tokenData.logout = false;
	}

	const contextValue = {
		token: token,
		isLoggedIn: userIsLoggedIn,
		isAdmin: admin,
		login: loginHandler,
		logout: logoutHandler,
		photo: photo,
		userName: userName,
		email: email,
	};

	return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
