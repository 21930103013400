import React, { useState, useEffect, useRef } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

/**
 * All parameters should be passed as React props.
 * @param {string} main - The text to be edited.
 * @param {boolean} disabled - When the element should be disabled (i.e. while saving).
 * @param {function} mainChangeHandler - A function that will be called with an object containing two items: text (string) and valid (boolean). Used to elevate the text being edited (main).
 * @param {boolean} valid - When the text is valid.
 * @param {boolean} includeImages - When images should be allowed in the text editor.
 * @returns JSX Element
 */
export default function TextEditor({ main, disabled, mainChangeHandler, valid, includeImages }) {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [touched, setTouched] = useState(false);
	const initialized = useRef(false);

	// Initialize the editor state only if it hasn't been initialized already
	useEffect(() => {
		if (!initialized.current && main) {
			const blocksFromHtml = htmlToDraft(main);
			const { contentBlocks, entityMap } = blocksFromHtml;
			const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
			setEditorState(EditorState.createWithContent(contentState));
			initialized.current = true; // Prevent re-initialization on subsequent renders
		}
	}, [main]);

	// Handle editor state changes
	const handleEditorStateChange = (state) => {
		setEditorState(state);
		const rawContent = convertToRaw(state.getCurrentContent());
		const htmlContent = draftToHtml(rawContent);
		const isValid = htmlContent.trim() !== '<p></p>' && htmlContent.trim() !== '';
		mainChangeHandler({ text: htmlContent, valid: isValid });
	};

	// Handle blur event to mark as touched
	const handleBlur = () => {
		setTouched(true);
	};

	const uploadImageCallback = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				resolve({ data: { link: reader.result } });
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	};

	const toolbarOptions = {
		options: ['blockType', 'fontSize', 'inline', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
		inline: { inDropdown: false },
		list: { inDropdown: false },
		textAlign: { inDropdown: true },
		link: { inDropdown: false },
		history: { inDropdown: false },
		...(includeImages && {
			options: ['blockType', 'fontSize', 'inline', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'history'],
			image: {
				uploadCallback: uploadImageCallback,
				previewImage: true,
				alt: { present: true, mandatory: false },
			},
		}),
	};

	return (
		<Editor
			editorState={editorState}
			toolbarClassName='toolbarClassName'
			wrapperClassName='wrapperClassName'
			editorClassName='editorClassName'
			onEditorStateChange={handleEditorStateChange}
			onBlur={handleBlur}
			toolbar={toolbarOptions}
			readOnly={disabled}
			editorStyle={{
				height: '300px',
				border: !touched ? '1px solid #c4c4c4' : valid ? '1px solid #c4c4c4' : '1px solid red',
				borderRadius: '8px',
				padding: '0 8px',
				backgroundColor: disabled ? '#f0f0f0' : !touched ? 'white' : valid ? 'white' : '#f5e7e7',
				color: disabled ? '#c4c4c4' : '',
			}}
		/>
	);
}
