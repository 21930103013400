import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import parserOptions from '../ui/ParserOptions';

import classes from './FacilityInfoCard.module.css';
import newIcon from '../images/New_Transparent_Background.png';
import updatedIcon from '../images/Updated_Transparent_Background.png';
import FileCard from './FileCard';
import { Box } from '@mui/material';

export default function FacilityInfoCard(props) {
	const [open, setOpen] = useState(false);
	const Card = props.info;

	const NOW = new Date();

	const handleClick = () => {
		if (open) {
			setOpen(false);
		} else {
			setOpen(true);
		}
	};

	//if the delete button is clicked, then passes the contents of the Card up to FacilityInfo
	const handleDelete = () => {
		props.selectDeleteEntry(Card);
	};

	//if edit button is clicked, then pass the contents of the Card up to FacilityInfo
	const handleEdit = () => {
		props.selectEditEntry(Card);
	};

	//stops event propogation for clicking on the file attachments in a fileCard
	const stopPropagation = (event) => {
		event.stopPropagation();
	};

	const formattedDate = Card.Date.toDate().toLocaleString();
	const formattedUpdatedDate = Card.UpdatedDate?.toDate().toLocaleString() || null;
	const isNew = Card.Date.toDate().getTime() + 1000 * 60 * 60 * 24 * 14 > NOW;
	const isUpdated = !isNew && Card.UpdatedDate && Card.UpdatedDate.toDate().getTime() + 1000 * 60 * 60 * 24 * 14 > NOW;

	return (
		<Box className={classes.outterContainer} mr={1}>
			{/*show new icon if less than 14 days old*/}
			{isNew && <img src={newIcon} className={classes.newImage} alt='new' />}

			{/*show updated icon if updated less than 14 days old and not new*/}
			{isUpdated && (
				<Box sx={{ display: 'block', textAlign: 'right' }}>
					<img src={updatedIcon} className={classes.updatedIcon} alt='updated' />
				</Box>
			)}

			<div className={`${classes.container} ${open && classes.expand}`} onClick={handleClick}>
				{/*upper container*/}
				<div className={`${classes.upper} ${open && classes.expand} ${!open && classes.closed}`}>
					<div className={classes.titleTag}>{Card.Title}</div>
					<section>
						<span>{Card.Summary}</span>
					</section>
				</div>

				{/*lower container*/}
				<div className={classes.lower}>
					<div className={classes.main}>{parse(Card.Main, parserOptions)}</div>
					{Card.Files && Card.Files.length > 0 && (
						<div className={classes.largeFileContainer} onClick={stopPropagation}>
							{Card.Files?.length > 0 &&
								Card.Files.map((file, index) => (
									<div className={classes.smallFileContainer} key={index}>
										<FileCard name={file.name} type={file.type} url={file.url} />
									</div>
								))}
						</div>
					)}
					<hr />
					<div className={classes.posted}>
						<div>Posted: {formattedDate}</div>
						<div>By: {Card.PostedBy}</div>
					</div>
					{isUpdated && (
						<div className={classes.posted}>
							<div>Updated: {formattedUpdatedDate}</div>
							<div>By: {Card.UpdatedBy}</div>
						</div>
					)}
				</div>
			</div>

			{props.page === 'Admin' && open && (
				<div className={classes.center}>
					<button className={classes.editButton} onClick={handleEdit}>
						Edit
					</button>
					<button className={classes.deleteButton} onClick={handleDelete}>
						Delete
					</button>
				</div>
			)}
		</Box>
	);
}
