import React, { useContext, useEffect, useState } from 'react';
import {
	getAuth,
	signInWithPopup,
	GoogleAuthProvider,
	setPersistence,
	browserSessionPersistence,
	// onAuthStateChanged,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

import AuthContext from './authContext';
import classes from './SignIn.module.css';
import EPTEmailModal from './EPTEmailModal';
import LoadingSpinner from '../ui/LoadingSpinner';

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
	hd: 'ept911.com',
});

export default function SignIn() {
	const auth = getAuth();
	const authCtx = useContext(AuthContext);
	const [displayUnverifiedModal, setDisplayUnverifiedModal] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [timer, setTimer] = useState(5);
	const [token, setToken] = useState();
	const [user, setUser] = useState();
	let intervalTimer = 0;

	//creates countdown for EPTEmailModal
	useEffect(() => {
		if (displayUnverifiedModal) {
			intervalTimer = setInterval(() => {
				setTimer((timer) => timer - 1);
			}, 1000);
			setTimeout(() => {
				setDisplayUnverifiedModal(null);
				clearInterval(intervalTimer);
				setTimer(5);
			}, 5000);
		}
	}, [displayUnverifiedModal]);

	//cancels the file modal displayed
	const cancelEPTEmailModal = () => {
		setDisplayUnverifiedModal(null);
		clearInterval(intervalTimer);
		setTimer(5);
	};

	//handles signIn
	const signInHandler = () => {
		setIsLoading(true);

		setPersistence(auth, browserSessionPersistence)
			.then(() => {
				signInWithPopup(auth, provider).then(async (result) => {
					const credential = GoogleAuthProvider.credentialFromResult(result);
					const token = credential.accessToken;
					setToken(token);
					const user = result.user;
					setUser(user);
				});
			})
			.catch((error) => {
				authCtx.logout();
				console.log(error);
			});
	};

	//verifies user, deletes users without @ept911.com account, sets custom claims inside the verifyEPTEmailWithCustomClaimsV2 function
	useEffect(() => {
		if (user && token) {
			let verified = false;
			const expirationTime = user.stsTokenManager.expirationTime;
			const photo = user.providerData[0].photoURL;
			const userName = user.displayName;
			const email = user.email;
			const uid = user.uid;
			// console.log(user);

			const functions = getFunctions();
			const verifyEPTEmailWithCustomClaimsV2 = httpsCallable(functions, 'verifyEPTEmailWithCustomClaimsV2');
			verifyEPTEmailWithCustomClaimsV2({}).then((res) => {
				verified = res.data.verified;

				if (verified) {
					authCtx.login(token, expirationTime, photo, userName, email, uid);
					setIsLoading(false);
				}
				//else logout the user and the verifyEPTEmailWithCustomClaimsV2 will have already deleted their account
				else {
					authCtx.logout();
					setIsLoading(false);
					setDisplayUnverifiedModal(true);
				}
			});

			return () => {
				verified = false;
			};
		}
	}, [user, token]);

	//if loading return loader...
	if (isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<React.Fragment>
			{displayUnverifiedModal && <EPTEmailModal onClose={cancelEPTEmailModal} timer={timer} />}
			<div>
				<div className={classes.menuContainer}>
					<button onClick={signInHandler} className={classes.menuTrigger}>
						Sign In
					</button>
				</div>
			</div>
		</React.Fragment>
	);
}
