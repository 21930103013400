import React, { useState, useContext, useEffect } from 'react';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import parse from 'html-react-parser';
import TextEditor from '../textEditor/TextEditor';

import FacilityInfoSubmission from './FacilityInfoSubmission';
import FileUploads from './FileUploads';
import AuthContext from '../auth/authContext';

import classes from './FacilityInfoForm.module.css';

export default function FacilityInfoForm(props) {
	const authCtx = useContext(AuthContext);
	const token = authCtx.token;
	const author = authCtx.userName;
	const initialDate = new Date();
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [initialLoaded, setInitialLoaded] = useState(false);
	const [submissionError, setSubmissionError] = useState(null);
	const [submissionSuccess, setSubmissionSuccess] = useState(null);
	const [loading, setLoading] = useState(false);
	const defaultFacilities = ['SBLH', 'SI', 'SLH', 'SNGH', 'SOH', 'SPAH', 'SVBGH', 'SSU SOH', 'TEST'];

	let topic = {
			Title: '',
			Summary: '',
			Main: '',
			Files: [],
			Facilities: [],
			Date: initialDate,
			PostedBy: null,
		},
		titleValidInitial = false,
		titleTouchedInitial = false,
		summaryValidInitial = false,
		summaryTouchInitial = false,
		mainValidInitial = false,
		mainTouchedInitial = false,
		facilitiesValidInitial = false,
		facilitiesTouchedInitial = false,
		formValidInitial = false,
		formTouchedInitial = false;

	//if props are passed (selecting editEntry) then set the valid and initial states to true
	if (props?.editEntry?.Title) {
		topic = {
			Title: props.editEntry.Title,
			Summary: props.editEntry.Summary,
			Main: props.editEntry.Main,
			Files: props.editEntry.Files,
			Facilities: props.editEntry.Facilities,
			Date: props.editEntry.Date.toDate(),
			PostedBy: props.editEntry.PostedBy,
		};

		titleValidInitial = true;
		titleTouchedInitial = true;
		summaryValidInitial = true;
		summaryTouchInitial = true;
		mainValidInitial = true;
		mainTouchedInitial = true;
		facilitiesValidInitial = true;
		facilitiesTouchedInitial = true;
		formValidInitial = true;
		formTouchedInitial = true;
	}

	const date = topic.Date;
	const [title, setTitle] = useState(topic.Title);
	const [summary, setSummary] = useState(topic.Summary);
	const [main, setMain] = useState(topic.Main);
	const [facilities, setFacilities] = useState(topic.Facilities);

	//if editing an entry, topic.files will not be null, so setUploadedFiles state to that
	useEffect(() => {
		if (topic?.Files !== null && uploadedFiles?.length === 0 && !initialLoaded) setUploadedFiles(topic.Files);
	}, []);

	//set initialLoaded to true so that FileUploads doesn't keep reloading props.initialFiles
	useEffect(() => {
		if (uploadedFiles?.length > 0) {
			setInitialLoaded(true);
		}
	}, [uploadedFiles]);

	const [titleValid, setTitleValid] = useState(titleValidInitial);
	const [titleTouched, setTitleTouched] = useState(titleTouchedInitial);
	const [titleLengthNotification, setTitleLengthNotification] = useState(false);
	const [summaryValid, setSummaryValid] = useState(summaryValidInitial);
	const [summaryTouched, setSummaryTouched] = useState(summaryTouchInitial);
	const [summaryLengthNotification, setSummaryLengthNotification] = useState(false);
	const [mainValid, setMainValid] = useState(mainValidInitial);
	const [mainTouched, setMainTouched] = useState(mainTouchedInitial);
	const [facilitiesValid, setFacilitiesValid] = useState(facilitiesValidInitial);
	const [facilitiesTouched, setFacilitiesTouched] = useState(facilitiesTouchedInitial);
	const [formValid, setFormValid] = useState(formValidInitial);
	const [formTouched, setFormTouched] = useState(formTouchedInitial);
	const [formSubmittedError, setFormSubmittedError] = useState(false);

	const titleChangeHandler = (event) => {
		setTitle(event.target.value);
		if (event.target.value.trim() !== '') {
			setTitleValid(true);
		}

		if (event.target.value.length >= 50) {
			setTitleLengthNotification(true);
		} else {
			setTitleLengthNotification(false);
		}
	};

	const titleBlurHandler = (event) => {
		setTitleTouched(true);
		if (event.target.value.trim() === '') {
			setTitleValid(false);
		}
	};

	const summaryChangeHandler = (event) => {
		setSummary(event.target.value);
		if (event.target.value.trim() !== '') {
			setSummaryValid(true);
		}

		if (event.target.value.length >= 100) {
			setSummaryLengthNotification(true);
		} else {
			setSummaryLengthNotification(false);
		}
	};

	const summaryBlurHandler = (event) => {
		setSummaryTouched(true);
		if (event.target.value.trim() === '') {
			setSummaryValid(false);
		}
	};

	const mainChangeHandler = (obj) => {
		const parsedTexted = parse(obj.text).toString();
		setMain(obj.text);
		if (parsedTexted.trim() !== '') {
			setMainValid(true);
		}
		setMainTouched(true);
		if (obj.text.index === 0) {
			setMainValid(false);
		}
	};

	//elevates any files uploaded/deleted into this FacilityInfoForm from FileUploads
	const handleFileChanges = (files) => {
		setUploadedFiles(files);
	};

	//sets alert if posting was successful or not
	useEffect(() => {
		if (submissionSuccess) {
			window.scrollTo(0, 0);
			props.formSubmissionSuccess();
		} else if (submissionError) {
			setLoading(false);
			setFormSubmittedError(true);
		}
	}, [submissionError, submissionSuccess]);

	const facilitiesChangeHandler = (event) => {
		setFacilitiesTouched(true);
		const facility = event.target.id;
		if (facilities.includes(facility)) {
			// Remove facility
			setFacilities(facilities.filter((f) => f !== facility));
		} else {
			// Add facility
			setFacilities([...facilities, facility]);
		}
	};

	//checks if at least one single facility has been selected on the form
	const facilitiesCheck = () => {
		if (facilities.length > 0) {
			setFacilitiesValid(true);
		} else {
			setFacilitiesValid(false);
		}
	};

	//checks if facilities have been selected each time the facilities checkboxes are updated
	useEffect(() => {
		facilitiesCheck();
	}, [facilities]);

	//handles the submission button being pushed
	const handleSubmitButton = async (event) => {
		event.preventDefault();
		setLoading(true);

		//upload any files that haven't been yet
		const filesUploaded = [];
		if (uploadedFiles?.length > 0)
			for (const file of uploadedFiles) {
				if (!file.url) {
					const storage = getStorage();
					const storageRef = ref(storage, `files/${date}/${file.name}`);
					await uploadBytes(storageRef, file);
					const URL = await getDownloadURL(storageRef);

					const fileData = { name: file.name, reference: `files/${date}/${file.name}`, type: file.type, url: URL };
					filesUploaded.push(fileData);
				} else {
					filesUploaded.push(file);
				}
			}

		let PostedBy, UpdatedDate, UpdatedBy;
		if (topic.PostedBy === null) {
			PostedBy = author;
			UpdatedBy = null;
			UpdatedDate = null;
		} else {
			PostedBy = topic.PostedBy;
			UpdatedBy = author;
			UpdatedDate = new Date();
		}

		const success = await FacilityInfoSubmission(
			date,
			PostedBy,
			UpdatedDate,
			UpdatedBy,
			title,
			summary,
			main,
			filesUploaded,
			facilities,
			token,
			props?.editEntry?.id || null
		);

		//if response is true (succeded), then move the files
		if (success) {
			setFormSubmittedError(false); //resets submitted attempt if there was an error
			setSubmissionSuccess(true);
		} else {
			setSubmissionError(true);
		}
	};

	//checks to see if the form is valid, controls the submission button access
	useEffect(() => {
		if (titleValid && summaryValid && mainValid && facilitiesValid) {
			setFormValid(true);
			setFormTouched(true);
		} else {
			setFormValid(false);
		}
	}, [titleValid, summaryValid, mainValid, facilitiesValid]);

	//handles if the submission button is pressed when the formValid is false
	const handleFormNotValid = (event) => {
		event.preventDefault();
		setFormTouched(true);
		setTitleTouched(true);
		setSummaryTouched(true);
		setMainTouched(true);
		setFacilitiesTouched(true);
	};

	//resets the form
	const resetForm = () => {
		props.resetForm();
	};

	return (
		<form>
			<div className={classes.formControl}>
				{/*form components*/}
				{/*title section*/}
				<>
					<label htmlFor='title'>
						Title
						{!titleValid && titleTouched && <span className={classes.error}>&nbsp;- a Title must be entered.</span>}
						{titleLengthNotification && (
							<span className={classes.error}>&nbsp;- the max of 50 characters for the title has been reached.</span>
						)}
					</label>
					<input
						className={!titleValid && titleTouched ? classes.error : ''}
						id='title'
						type='text'
						maxLength='50'
						value={title}
						onChange={titleChangeHandler}
						onBlur={titleBlurHandler}
					></input>
				</>

				{/*summary section*/}
				<>
					<label htmlFor='summary'>
						Summary
						{!summaryValid && summaryTouched && (
							<span className={classes.error}>&nbsp;- a Summary must be entered.</span>
						)}
						{summaryLengthNotification && (
							<span className={classes.error}>&nbsp;- the max of 100 characters for the summary has been reached.</span>
						)}
					</label>
					<textarea
						className={!summaryValid && summaryTouched ? classes.error : ''}
						id='summary'
						type='text'
						maxLength='100'
						value={summary}
						rows='2'
						onChange={summaryChangeHandler}
						onBlur={summaryBlurHandler}
					></textarea>
				</>

				{/*main section*/}
				<label htmlFor='main'>
					Main
					{!mainValid && mainTouched && <span className={classes.error}>&nbsp;- Content must be entered.</span>}
				</label>

				<TextEditor
					main={topic.main}
					disabled={loading}
					mainChangeHandler={mainChangeHandler}
					valid={mainValid}
					includeImages={true}
				/>

				{/*file upload section*/}
				<FileUploads date={topic.Date.getTime()} fileChanges={handleFileChanges} initialFiles={uploadedFiles} />

				{/*facilities checkbox section*/}
				<>
					<label htmlFor='facilities'>
						Facilities
						{!facilitiesValid && facilitiesTouched && (
							<span className={classes.error}>
								&nbsp;- Please select at least one facility to post this information to.
							</span>
						)}
					</label>
					<div className={!facilitiesValid && facilitiesTouched ? classes.bigBoxError : classes.bigBox}>
						{defaultFacilities.map((defaultFacility, index) => {
							return (
								<div className={classes.smallBox} key={index}>
									<label id={defaultFacility}>{defaultFacility}</label>
									<input
										id={defaultFacility}
										type='checkbox'
										checked={facilities.includes(defaultFacility)}
										onChange={facilitiesChangeHandler}
									/>
								</div>
							);
						})}
					</div>
				</>

				{/*form buttons*/}
				<div className={classes.center}>
					{loading && (
						<div className={classes.spinner}>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					)}
					{!loading && formValid && formTouched && (
						<button className={classes.buttonFacilityForm} onClick={handleSubmitButton}>
							Submit
						</button>
					)}
					{!loading && !formValid && (
						<button className={classes.buttonFacilityFormInvalidButton} onClick={handleFormNotValid}>
							Submit
						</button>
					)}
					<button className={classes.buttonFacilityFormReset} onClick={resetForm}>
						Reset
					</button>
				</div>

				{formSubmittedError && <div className={classes.submissionError}>Something went wrong, please try again.</div>}
			</div>
		</form>
	);
}
