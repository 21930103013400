import React, { Fragment, useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { Link } from 'react-router-dom';

import { Box, Avatar, Menu, MenuItem, ListItemIcon, Divider, IconButton, Typography } from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import Tooltip from './Tooltip';
import AuthContext from '../auth/authContext';
import CustomClaimsContext from '../auth/CustomClaimsContext';

var pkg = require('../../../package.json');

export default function AvatarMenu(props) {
	const auth = getAuth();
	const authCtx = useContext(AuthContext);
	const claimsCtx = useContext(CustomClaimsContext);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const signOutHandler = () => {
		signOut(auth)
			.then(() => {
				authCtx.logout();
			})
			.catch((error) => {
				console.log(error);
				// An error happened.
			});
	};

	return (
		<Fragment>
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
				<Tooltip title='Additional Options'>
					<IconButton
						onClick={handleClick}
						size='small'
						sx={{ ml: 2 }}
						aria-controls={open ? 'account-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}
					>
						<Avatar src={authCtx.photo} name={props.userName} sx={{ width: 56, height: 56 }} />
					</IconButton>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id='account-menu'
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 28,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{claimsCtx?.claims?.admin && (
					<MenuItem component={Link} to='/admin_edit_cards'>
						<ListItemIcon>
							<Settings fontSize='small' />
						</ListItemIcon>
						Admin - Edit Cards
					</MenuItem>
				)}

				{claimsCtx?.claims?.admin && (
					<MenuItem component={Link} to='/admin_edit_pages'>
						<ListItemIcon>
							<Settings fontSize='small' />
						</ListItemIcon>
						Admin - Edit Pages
					</MenuItem>
				)}

				{claimsCtx?.claims?.admin && <Divider />}

				<MenuItem onClick={signOutHandler}>
					<ListItemIcon>
						<Logout fontSize='small' />
					</ListItemIcon>
					Sign Out
				</MenuItem>

				<br />
				<Box sx={{ alignContent: 'center', display: 'flex', justifyContent: 'center' }}>
					<Typography variant='caption'>Version {pkg.version}</Typography>
				</Box>
			</Menu>
		</Fragment>
	);
}
