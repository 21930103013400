import React from 'react';
import Modal from '../ui/Modal';

import classes from './EPTEmailModal.module.css';

export default function EPTEmailModal(props) {
	const handleCancel = () => {
		props.onClose();
	};

	return (
		<Modal onClose={handleCancel}>
			<div className={classes.statement}>An EPT Email account must be used to login.</div>
			<br></br>
			<div className={classes.statement}>This window will close automatically in {props.timer} seconds.</div>
		</Modal>
	);
}
