import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';

import PageCards from '../ui/PageCards';
import FacilityInfoCard from './FacilityInfoCard';
import classes from './FacilityInfo.module.css';
import LoadingSpinner from '../ui/LoadingSpinner';
import { Box } from '@mui/material';
import { db } from '../../App';

export default function FacilityInfo(props) {
	const [isLoading, setIsLoading] = useState(true);
	const [loadedList, setLoadedList] = useState([]);
	const [facility, setFacility] = useState(props.facility);

	// gets the facility list of info cards, sorts them by date and then alphabetically
	useEffect(() => {
		let isMounted = true;

		const getFacilityInfo = async () => {
			setIsLoading(true);

			const facilityRef = collection(db, 'FacilityInfo');
			let q;
			if (props.facility === 'ALL') q = query(facilityRef);
			else q = query(facilityRef, where('Facilities', 'array-contains', props.facility));
			const querySnapshot = await getDocs(q);

			const facilityList = [];
			querySnapshot.forEach((doc) => {
				facilityList.push({ ...doc.data(), id: doc.id });
			});

			function compare(a, b) {
				if (a.Title < b.Title) {
					return -1;
				}
				if (a.Title > b.Title) {
					return 1;
				}
				return 0;
			}

			const NOW = new Date().getTime();

			let newItems = [];
			let updatedItems = [];
			let oldItems = [];

			for (let i = 0; i < facilityList.length; i++) {
				const item = facilityList[i];
				const itemDate = item.Date.toDate().getTime();
				const itemUpdatedDate = item.UpdatedDate ? item.UpdatedDate.toDate().getTime() : null;

				if (itemDate + 1000 * 60 * 60 * 24 * 14 > NOW) {
					newItems.push(item);
				} else if (itemUpdatedDate && itemUpdatedDate + 1000 * 60 * 60 * 24 * 14 > NOW) {
					updatedItems.push(item);
				} else {
					oldItems.push(item);
				}
			}

			newItems.sort(compare);
			updatedItems.sort(compare);
			oldItems.sort(compare);

			const newList = newItems.concat(updatedItems).concat(oldItems);

			if (isMounted) {
				setLoadedList(newList);
				setIsLoading(false);
			}
		};
		getFacilityInfo();

		return () => {
			isMounted = false;
		};
	}, [props.addedItem, props.deletedItem, props.facility]);

	// renames SSU facilities to display name correctly
	useEffect(() => {
		if (props.facility === 'SSU_SOH') {
			setFacility('SOH SSU');
		}
	}, [props.facility]);

	// if delete button selected on FacilityInfoCard, then continue to pass card contents up to Admin
	const handleDeleteEntry = (obj) => {
		props.selectDeleteEntry(obj);
	};

	// if edit button selected on FacilityInfoCard, then continue to pass card contents up to Admin
	const handleEditEntry = (obj) => {
		props.selectEditEntry(obj);
	};

	if (isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<React.Fragment>
			<PageCards>
				{props.page !== 'Admin' && (
					<Box>
						<h3>On Shift Info</h3>
						<section>
							Use the information in the card(s) below when on shift at{' '}
							<span className={classes.facilityName}>{facility}</span>. Click a card to expand a section of interest.
						</section>
					</Box>
				)}
				<Box mt={2} mr={1} ml={1} p={1} className={classes.scrollableSection}>
					{loadedList.map((facilityInfo, index) => (
						<FacilityInfoCard
							key={index}
							info={facilityInfo}
							selectEditEntry={handleEditEntry}
							selectDeleteEntry={handleDeleteEntry}
							facility={props.facility}
							page={props.page}
						/>
					))}
				</Box>
			</PageCards>
		</React.Fragment>
	);
}
