import { collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../App';

const FacilityInfoSubmission = (
	date,
	postedBy,
	updatedDate,
	updatedBy,
	title,
	summary,
	main,
	fileArray,
	facilities,
	token,
	key
) => {
	return new Promise(async (succeeded, failed) => {
		//checks for files being added to the post
		let uploadedFiles;
		if (fileArray.length === 0) {
			uploadedFiles = [];
		} else {
			uploadedFiles = fileArray;
		}

		//defines the body of the post
		const body = {
			Date: date,
			PostedBy: postedBy,
			UpdatedDate: updatedDate,
			UpdatedBy: updatedBy,
			Title: title,
			Summary: summary,
			Main: main,
			Files: uploadedFiles,
			Facilities: facilities,
		};

		//posts data
		try {
			if (!key) await addDoc(collection(db, 'FacilityInfo'), body);
			else {
				const docRef = doc(db, 'FacilityInfo', key);
				await updateDoc(docRef, body);
			}
			succeeded(true);
		} catch (err) {
			console.error(err);
			succeeded(false);
		}
	});
};

export default FacilityInfoSubmission;
