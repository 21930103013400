import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, child } from 'firebase/database';
import classes from './List.module.css';

import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '../ui/Tooltip';
import IconButton from '@mui/material/IconButton';
import LoadingSpinner from '../ui/LoadingSpinner';
import { Box, Stack } from '@mui/material';

export default function List(props) {
	const [isLoading, setIsLoading] = useState(true);
	const [loadedList, setLoadedList] = useState([]);
	const [enteredName, setEnteredName] = useState(''); // the value of the search field
	const [foundName, setFoundName] = useState(loadedList); // the search result

	useEffect(() => {
		let isMounted = true;
		setIsLoading(true);
		const dbRef = ref(getDatabase());
		get(child(dbRef, `admissionlists/${props.facility}`))
			.then((snapshot) => {
				if (isMounted) {
					if (snapshot.exists()) {
						const data = snapshot.val();
						const providersList = [];

						for (const key in data) {
							const provider = {
								id: key,
								...data[key],
							};
							providersList.push(provider);
						}

						setIsLoading(false);
						setLoadedList(providersList);
						setFoundName(providersList);
					} else {
						setIsLoading(false);
						setLoadedList([{ id: 0, name: 'Error - No Data Available', group: 'Error' }]);
						setFoundName([{ id: 0, name: 'Error - No Data Available', group: 'Error' }]);
					}
				}
			})
			.catch((error) => {
				if (isMounted) {
					setIsLoading(false);
					setLoadedList([{ id: 0, name: 'Error - No Data Available', group: 'Error' }]);
					setFoundName([{ id: 0, name: 'Error - No Data Available', group: 'Error' }]);
				}
				console.error(error);
			});

		return () => {
			isMounted = false;
		};
	}, [props.facility]);

	//handles downloadList
	const downloadListHandler = (event) => {
		const list = [];
		loadedList.forEach((element) => {
			list.push([element.name, element.group]);
		});

		const workbook = new Excel.Workbook();
		const worksheet = workbook.addWorksheet(`${props.facility.toUpperCase()} Sheet`);
		worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }];

		worksheet.columns = [
			{ header: 'Name', key: 'name' },
			{ header: 'Group', key: 'group' },
		];
		const nameCol = worksheet.getColumn(1);
		const groupCol = worksheet.getColumn(2);
		nameCol.width = 50;
		groupCol.width = 25;
		worksheet.addRows(list);

		workbook.xlsx.writeBuffer().then((data) => {
			const blob = new Blob([data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
			});
			FileSaver.saveAs(blob, props.facility.toUpperCase());
		});
	};

	//filters list
	const filter = (event) => {
		const keyword = event.target.value;

		if (keyword !== '') {
			const results = loadedList.filter((providerItem) => {
				// Use the toLowerCase() method to make it case-insensitive
				return providerItem.name.toLowerCase().includes(keyword.toLowerCase());
			});
			setFoundName(results);
		} else {
			// If the text field is empty, show all providers
			setFoundName(loadedList);
		}
		setEnteredName(keyword);
	};

	//if loading return loader...
	if (isLoading) {
		return <LoadingSpinner />;
	}

	//if not loading then return this
	return (
		<div className={classes.container}>
			<div className={classes.justify}>
				<input
					type='search'
					value={enteredName}
					onChange={filter}
					className={classes.input}
					placeholder='Search'
					autoFocus
				/>
				<Tooltip title='Download this Admission List'>
					<IconButton color='primary' aria-label='download admission list' onClick={downloadListHandler}>
						<DownloadIcon />
					</IconButton>
				</Tooltip>
			</div>

			<Box p={1} className={classes.providerList}>
				<Stack spacing={1}>
					{foundName && foundName.length > 0 ? (
						foundName.map((provider, index) => (
							<Box key={index} className={classes.provider}>
								<span className={classes.providerName}>{provider.name}</span>
								<span className={classes.providerGroup}>{provider.group}</span>
							</Box>
						))
					) : (
						<h3>No Results Found</h3>
					)}
				</Stack>
			</Box>
		</div>
	);
}
