import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import { AuthContextProvider } from './components/auth/authContext';
import { CustomClaimsContextProvider } from './components/auth/CustomClaimsContext';

ReactDOM.render(
	<AuthContextProvider>
		<CustomClaimsContextProvider>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</CustomClaimsContextProvider>
	</AuthContextProvider>,
	document.getElementById('root')
);
